// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cell-phone-repair-js": () => import("./../../../src/pages/cell-phone-repair.js" /* webpackChunkName: "component---src-pages-cell-phone-repair-js" */),
  "component---src-pages-cell-phone-repairs-landing-js": () => import("./../../../src/pages/cell-phone-repairs-landing.js" /* webpackChunkName: "component---src-pages-cell-phone-repairs-landing-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iphone-repairs-landing-js": () => import("./../../../src/pages/iphone-repairs-landing.js" /* webpackChunkName: "component---src-pages-iphone-repairs-landing-js" */),
  "component---src-pages-phone-accessories-js": () => import("./../../../src/pages/phone-accessories.js" /* webpackChunkName: "component---src-pages-phone-accessories-js" */),
  "component---src-templates-phone-accessories-details-js": () => import("./../../../src/templates/phone-accessories-details.js" /* webpackChunkName: "component---src-templates-phone-accessories-details-js" */),
  "component---src-templates-phone-details-js": () => import("./../../../src/templates/phone-details.js" /* webpackChunkName: "component---src-templates-phone-details-js" */),
  "component---src-templates-repair-devices-js": () => import("./../../../src/templates/repair-devices.js" /* webpackChunkName: "component---src-templates-repair-devices-js" */)
}


/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onClientEntry = () => {
    window.onload = () => {
        const timeCurrent = new Date();
        const todayCell = document.getElementById('calendarDayId'+timeCurrent.getDate());
        //const footerYear = document.getElementById('footerCurrentYear');

        if (todayCell) {
            todayCell.classList.add('calendarDayClicked');
        } 
        //footerYear.innerHTML = timeCurrent.getFullYear();
    }
  }